import "babel-polyfill";
import "jquery";
import "bootstrap/dist/js/bootstrap";

import "./extensions/DateExtension";
import "./extensions/ArrayExtension";
import "./extensions/StringExtension";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import "vue-multiselect/dist/vue-multiselect.min.css";

import numeral from "numeral";

import Vue from "vue";

import Messagebox from "./components/Modals/Messagebox";

import CKEditor from "@ckeditor/ckeditor5-vue2";
Vue.use(CKEditor);

/*** FORM VALIDATION */

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, regex } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("regex", regex);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

import Permissions from "./plugins/Berechtigungen";
import EnvironmentPlugin from "./plugins/environment";
import { isMobile, isAndroid, isWinPhone, isIOS } from "mobile-device-detect";

/*extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true
});*/

import Chartkick from "vue-chartkick";
import Chart from "chart.js";

Vue.use(Chartkick.use(Chart));

import "@fortawesome/fontawesome-pro/css/all.css";
import "@fortawesome/fontawesome-pro/js/all.js";

import VueRouter from "vue-router";
Vue.use(VueRouter);

import router from "./Router";
import store from "./store";

import App from "./App";

import {
  //  BootstrapVue,
  TablePlugin,
  FormCheckboxPlugin,
  FormPlugin,
  FormInputPlugin,
  DropdownPlugin,
  CollapsePlugin,
  CardPlugin,
  ButtonPlugin
} from "bootstrap-vue";

/*import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";*/

//Vue.use(BootstrapVue);
Vue.use(TablePlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormPlugin);
Vue.use(FormInputPlugin);
Vue.use(DropdownPlugin);
Vue.use(CollapsePlugin);
Vue.use(CardPlugin);
Vue.use(ButtonPlugin);

import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

Vue.use(VueTelInput, {
  country: {
    name: "Switzerland (Schweiz)",
    iso2: "CH",
    dialCode: "41",
    priority: 0,
    areaCodes: null
  },
  formatted: ""
});

import vueNumeralFilterInstaller from "vue-numeral-filter";
Vue.use(vueNumeralFilterInstaller, {
  locale: "de"
});

import ForbiddenContent from "@/components/global/ForbiddenContent";

Vue.component("forbidden-content", ForbiddenContent);
Vue.component("messagebox", Messagebox);

import VTooltip from "v-tooltip";
Vue.use(VTooltip);

import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

import "./utils/VueFilter";

numeral.locale("de");
numeral.localeData("de").delimiters.thousands = "'";
numeral.localeData("de").delimiters.decimal = ".";

import messages from "./internationalization/Messages";

import VueShortKey from "vue-shortkey";
Vue.use(VueShortKey);

import Notifications from "vue-notification";
Vue.use(Notifications);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

Vue.use(Permissions);

Vue.use(EnvironmentPlugin, {
  isMobile: isMobile || isAndroid || isWinPhone || isIOS
});

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import datepicker from "./components/Datepicker";
Vue.component("datepicker", datepicker);

import PdfLayer from "./components/global/PdfLayer";
Vue.component("pdf-layer", PdfLayer);

Vue.config.productionTip = false;

if (JSON.parse(process.env.VUE_APP_DEV_TOOLS)) Vue.config.devtools = true;

const i18n = new VueI18n({
  locale: "de", // set locale
  fallbackLocale: "de",
  messages // set locale messages
});

// bus aktivieren
Vue.prototype.$bus = new Vue();

new Vue({
  i18n,
  render: h => h(App),
  store,
  router
}).$mount("#erp-app");
