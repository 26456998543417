import axios from 'axios';
import router from './Router'

let apiConfig = {
  baseURL: process.env.VUE_APP_API_URL,
  defaultURL: process.env.VUE_APP_SERVER_URL,
  withCredentials: true
}

const Api = axios.create(apiConfig);

Api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    if (error.response.status === 401) {
      if (router.currentRoute.name !== 'login')
        router.push({ name: 'login' })
    }
  }

  return Promise.reject(error);
})

export default Api
