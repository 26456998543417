<template>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    @click="close"
  >
    <span aria-hidden="true"><i class="fa fa-times text-white"></i></span>
  </button>
</template>

<script>
export default {
  name: "ModalCloseButton",
  methods: {
    close() {
      this.$emit("confirmed");
    },
  },
};
</script>

<style>
</style>