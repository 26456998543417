import store from "../store";

export default {
  install: function(Vue) {
    Vue.mixin({
      computed: {
        berechtigungen() {
          return store.state.berechtigungen;
        },
        gruppen() {
          return store.state.gruppen;
        }
      }
    });
  }
};
