
/*Exapmle ABBTS*/
/* Positionen */

export const SET_POSITIONEN = "setPositionen";
export const ADD_POSITIONEN = "addPositionen";
export const ADD_POSITION = "addPosition";
export const CHANGE_POSITION = "changePosition";
export const CHANGE_UNTERPOSITION = "changeUnterposition";
export const SWAP_POSITIONEN = "swapPositionen";
export const REMOVE_POSITIONEN = "removePosition";

/* Beleg */

export const SET_GEBUCHT = "setGebucht";
export const SET_LIEFERANT = "setLieferant";
export const SET_BELEGTYP = "setBelegTyp";
export const ADD_LIEFERANT = "addLieferant";
export const SET_BELEG_STATISTIK = "setBelegstatistik";

/* Erinnerungen */

export const SET_ERINNERUNGEN = "setErinnerungen";
export const CHANGE_ERINNERUNG = "changeErinnerung";
export const ADD_ERINNERUNG = "addErinnerung";


/* Session */

export const SET_MITARBEITER = "setMitarbeiter";
export const SET_PERMISSIONS = "setPermissions";

/* Viewport */

export const SET_WINDOW_HEIGHT = "setWindowHeight";
export const SET_WINDOW_WIDTH = "setWindowWidth";

export const SET_BERECHTIGUNGEN = "setBerechtigungen";
export const SET_GRUPPEN = "setGruppen";
