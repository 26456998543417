import axios from 'axios';
import router from './Router'

let apiConfig = {
  baseURL: process.env.VUE_APP_PERMISSIONS_URL,
  defaultURL: process.env.VUE_APP_SERVER_URL,
  withCredentials: true
}

const permissions = axios.create(apiConfig);

export default permissions

