import Vue from "vue";
import Vuex from "vuex";

import { uuidv4 } from "../utils/Uuid";
import "../extensions/ArrayExtension";

import {
  SET_WINDOW_HEIGHT,
  SET_WINDOW_WIDTH,
  SET_MITARBEITER,
  SET_BERECHTIGUNGEN
} from "./mutations.type";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //bonitaeten: [],

    selectedEquities: [],
    windowHeight: 0,
    windowWidth: 0,
    permissions: null,
    mitarbeiter: null
  },
  mutations: {
    /*
    [SET_PERMISSIONS](state, permissions) {
      state.permissions = permissions;
    },
    [ADD_POSITION](state, postion) {
      postion.key = uuidv4();
      state.positionen.push(postion);
    },
    [ADD_POSITIONEN](state, positionen) {
      positionen.forEach(p => {
        p.key = uuidv4();
        state.positionen.push(p);
      });
    },*/
    [SET_WINDOW_HEIGHT](state, height) {
      state.windowHeight = height;
    },
    [SET_WINDOW_WIDTH](state, width) {
      state.windowWidth = width;
    },
    [SET_MITARBEITER](state, mitarbeiter) {
      state.mitarbeiter = mitarbeiter;
    },
    [SET_BERECHTIGUNGEN](state, val) {
      state.berechtigungen = val;
    }
  },
  modules: {},
  getters: {
    /* name: state =>
      state.mitarbeiter
        ? `${state.mitarbeiter.vorname} ${state.mitarbeiter.nachname}`
        : "", */
    berechtigungen: state => state.berechtigungen,
    mitarbeiter: state => state.mitarbeiter,
    mitarbeiterList: state => state.mitarbeiterList,
    windowHeight: state => state.windowHeight,
    windowWidth: state => state.windowWidth
  }
});
