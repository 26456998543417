<template>
  <div
    id="app"
    :class="{ 'margin-top-0': $route && $route.name === 'StartseiteArvea' }"
  >
    <!--  <router-view
      v-if="angemeldeterMitarbeiter || loginError"
    ></router-view> -->
    <router-view></router-view>
  </div>
</template>

<script>
import store from "@/store";
import {
  // SET_PERMISSIONS,
  SET_MITARBEITER,
  SET_BERECHTIGUNGEN
  /* SET_LAENDER,
  SET_ADRESSTYPEN,
  SET_BERECHTIGUNGEN,
  SET_GRUPPEN, */
} from "@/store/mutations.type";

// import { SET_EMAIL_TYPEN } from "@/store/geschaeftspartner/mutations.type";

import Api from "@/Api";
import Permissions from "@/Permissions";

export default {
  name: "App",
  components: {},
  data() {
    return {
      kategorienLoaded: false,
      loginError: false
    };
  },
  store,
  computed: {
    angemeldeterMitarbeiter: {
      get() {
        return this.$store.state.mitarbeiter;
      },
      set(value) {
        this.$store.commit(SET_MITARBEITER, value);
      }
    }
    /*   permissions: {
      get() {
        return this.$store.state.permissions;
      }, 
      set(val) {
        this.$store.commit(SET_PERMISSIONS, val);
      }, 
    },*/
  },
  async created() {
    /*if (!this.angemeldeterMitarbeiter)
      try {
        let response = await Api.get("angemeldeter-mitarbeiter/");
        this.angemeldeterMitarbeiter = response.data.mitarbeiter;

        //this.$store.commit(SET_BERECHTIGUNGEN, response.data.berechtigungen);
        this.$store.commit(SET_GRUPPEN, response.data.gruppen);

        // await this.ladeKategorien();
      } catch (e) {
        console.log("bin im catch app created angemeldeter mitarbeiter");
        this.loginError = true;
      }*/
    //console.log("Halthcheck: ")
   //Api.get("http://localhost:8081/healthcheck").then((response) => {console.log(response)})
  },
  methods: {
    ladeBerechtigungen() {
      /*  Api.get("security/permissions/").then((response) => {
        this.permissions = response.data;
      }); */
    },
    async ladeKategorien() {
      /* try {
        let response = await Api.get("kategorien/");
        this.$store.commit(SET_LAENDER, response.data.laender);
        this.$store.commit(
          `geschaeftspartner/${SET_EMAIL_TYPEN}`,
          response.data.emailtypen
        );
        this.$store.commit(SET_ADRESSTYPEN, response.data.adresstypen);

        //await this.$store.dispatch(`rechnung/${LADE_KATEGORIEN}`);
        //await this.$store.dispatch(`artikel/${LADE_ARTIKELKATEGORIEN}`);
      } finally {
        this.kategorienLoaded = true;
      }
    },*/
    }
  }
};
</script>

<style lang="scss">
@import "./scss/app.scss";

.margin-top-70 {
  margin-top: 70px !important;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
