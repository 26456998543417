export default {
  daten: 'Daten',
  beleg: {
    beleg: 'Beleg',
    offeneBelege: 'Offene Belege'
  },
  artikel: {
    artikelMitName: 'Artikel {name}',
    neuerArtikel: 'Neuer Artikel',
    artikelnummer: 'Artikelnummer',
    kurztext: 'Kurztext',
    langtext: 'Langtext',
    ean: 'EAN',
    bestand: 'Bestand',
    bauteil: 'Bauteil | Bauteile',
    gesperrt: 'Gesperrt',
    materialzeugnis: 'Materialzeugnis | Materialzeugnisse',
    artikelgruppe: 'Artikelgruppe | Artikelgruppen',
    lagerplatz: 'Lagerplatz | Lagerplätze',
    lager: 'Lager | Lager',
    belegarchiv: 'Belegarchiv',
    neu: 'Neuer Artikel',
    mitName: 'Artikel {name}'
  },
  partner: {
    lieferant: 'Lieferant | Lieferanten',
    kunde: 'Kunde | Kunden',
    adressen: {
      anzahl: 'keine Adressen hinterlegt | eine Adresse hinterlegt | {count} Adressen hinterlegt'
    }
  },
  listen: {
    nichtGefunden: 'Keine {name} gefunden',
    aufklappen: 'aufklappen',
    zuklappen: 'zuklappen'
  },
  hinzufügen: '{name} hinzufügen',
  aktionen: {
    speichern: 'Speichern',
    abbrechen: 'Abbrechen',
    löschen: 'Löschen'
  },
  operations: {
    created: 'erstellt',
    changed: 'geändert',
    deleted: 'gelöscht',
    booked: 'gebucht'
  },
  status: {
    offen: 'open'
  },
  positionen: {
    anzahl: 'keine Positionen | eine Position | {count} Positionen'
  }
}
