<template>
  <div class="container-fluid">
    <div class="bg-white br-4 p-2">
      <h4 class="display-6">
        <i class="fas fa-lock mr-3"></i>Fehlende Berechtigungen
      </h4>
      <p
        class="lead"
      >Der angeforderte Bereich ist geschützt und Sie besitzen nicht die Rechte, diesen zu Besuchen.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForbiddenContent"
};
</script>

<style>
</style>