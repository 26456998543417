/* eslint-disable no-useless-escape */

String.prototype.trimLeft = function (charlist) {
  if (charlist === undefined)

    charlist = "\s";

  return this.replace(new RegExp("^[" + charlist + "]+"), "");
};

String.prototype.trimRight = function (charlist) {
  if (charlist === undefined)
    charlist = "\s";

  return this.replace(new RegExp("[" + charlist + "]+$"), "");
};

String.prototype.toGermanDate = function () {

  let parts = this.split('.');
  if (parts.length !== 3) return NaN

  return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]))
}