<template>
  <message-box
    :id="id"
    headerText="PDF Viewer"
    ref="msgBoxPdf"
    size="fluid"
    cancel-text="Schliessen"
    no-footer
    @cancel="messageboxClosed"
  >
    <template #header>
      <div class="w-100">
        <div v-if="isMobile" class="row d-none d-md-flex">
          <div class="col-md-3 text-left d-none d-md-block">
            <button
              class="btn btn-light"
              v-tooltip="'PDF nach links drehen'"
              @click="rotateLeft"
            >
              <i class="fas fa-undo"></i>
            </button>
            <button
              class="btn btn-light ml-1"
              v-tooltip="'PDF nach rechts drehen'"
              @click="rotateRight"
            >
              <i class="fas fa-undo mirror-horizonal"></i>
            </button>
          </div>

          <div class="text-center fs-18 font-weight-bold col-6">
            <button
              class="btn btn-light mr-3"
              :disabled="!hasPreviousPage"
              @click="previousPage"
            >
              <i class="ifr if-arrow-with-circle-left"></i>
            </button>
            <span>Seite {{ pdfPage }} von {{ pdfPages }}</span>
            <button
              class="btn btn-light ml-3"
              :disabled="!hasNextPage"
              @click="nextPage"
            >
              <i class="ifr if-arrow-with-circle-right"></i>
            </button>
          </div>
          <div class="col-md-3 text-right d-none d-md-block">
            <button class="btn btn-light" @click="hide">
              <i class="ifr if-cancel"></i>
            </button>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12 text-right">
            <button class="btn btn-light" @click="hide">
              <i class="ifr if-cancel"></i>
            </button>
          </div>
        </div>
      </div>
    </template>
    <div v-if="isMobile">
      <div class="pdf-wrapper">
        <pdf
          v-if="src && open"
          v-show="loadingProgress === 1"
          :key="pdfKey"
          :src="src"
          :page="pdfPage"
          :rotate="rotation"
          @num-pages="(n) => (pdfPages = n)"
          @loaded="loaded = true"
          @progress="(n) => (loadingProgress = n)"
        ></pdf>
        <div v-show="loadingProgress < 1" class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: progressPercent + '%' }"
            :aria-valuenow="progressPercent"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ loadingProgress | percentage }}
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 col-lg-3 col-1 d-none d-md-flex"></div>
        <div class="col-3 col-md-2 col-lg-1 text-md-right text-left">
          <button
            class="btn btn-light mr-3"
            :disabled="!hasPreviousPage"
            @click="previousPage"
          >
            <i class="ifr if-arrow-with-circle-left"></i>
          </button>
        </div>
        <div class="text-center fs-18 font-weight-bold col-6 col-md-4">
          Seite {{ pdfPage }} von {{ pdfPages }}
        </div>
        <div class="col-3 col-md-2 col-lg-1 text-md-left text-right">
          <button
            class="btn btn-light ml-3"
            :disabled="!hasNextPage"
            @click="nextPage"
          >
            <i class="ifr if-arrow-with-circle-right"></i>
          </button>
        </div>
        <div class="col-md-2 col-lg-3 text-right d-none d-md-block"></div>
      </div>
    </div>
    <div v-else>
      <iframe
        v-if="open"
        :src="iFrameUrl"
        :page="pdfPage"
        width="100%"
        :height="heightPixel"
        @load="loaded = true"
      ></iframe>
    </div>
  </message-box>
</template>

<script>
import { v4 as uuid } from "uuid";
import pdf from "vue-pdf";
import MessageBox from "@/components/Modals/Messagebox";
export default {
  name: "PdfLayer",
  components: {
    pdf,
    MessageBox,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    src: {
      default: null,
    },
  },
  data() {
    return {
      pdfKey: uuid(),
      pdfPage: 1,
      pdfPages: 0,
      loadingProgress: 0,
      loaded: false,
      open: false,
      rotation: 0,
    };
  },
  watch: {
    src: {
      deep: true,
      handler: function () {
        this.resetPdf();
      },
    },
  },
  computed: {
    progressPercent() {
      return this.loadingProgress * 100;
    },
    hasPreviousPage() {
      return this.page > 1;
    },
    hasNextPage() {
      return this.page < this.pdfPages;
    },
    iFrameUrl() {
      if (typeof this.src === "object") {
        return this.src.url;
      } else if (typeof this.src === "string") {
        return this.src;
      } else {
        return null;
      }
    },
    height() {
      return window.innerHeight;
    },
    heightPixel() {
      return this.height - 230 + "px";
    },
  },
  methods: {
    show() {
      this.$refs.msgBoxPdf.openModal();
      this.open = true;
    },
    hide() {
      this.$refs.msgBoxPdf.closeModal();
      this.open = false;
    },
    messageboxClosed() {
      this.resetPdf();
      this.open = false;
    },
    resetPdf() {
      this.pdfPages = 0;
      this.pdfPage = 1;
      this.loadingProgress = 0;
      this.rotation = 0;
      this.pdfKey = uuid();
    },
    nextPage() {
      this.page++;
    },
    previousPage() {
      this.page--;
    },
    rotateLeft() {
      if (this.rotation === 0) this.rotation = 270;
      else this.rotation -= 90;
    },
    rotateRight() {
      if (this.rotation === 270) this.rotation = 0;
      else this.rotation += 90;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  margin-top: 3rem;
  margin-bottom: 3rem;
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translateX(50%);
  width: 50%;
}

.pdf-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  background-color: white;
}
</style>
