export default {
    daten: 'Data',
    beleg: {
        beleg: 'Receipt',
        offeneBelege: 'Open Receipts'
    },
    artikel: {
        mitName: 'Product {name}',
        neu: 'New Product',
        artikelnummer: 'Article Number',
        kurztext: 'short Text',
        langtext: 'long Text',
        ean: 'EAN',
        bestand: 'Stock',
        bauteil: 'Component | Components',
        gesperrt: 'Locked',
        materialzeugnis: 'Material Certificate | Material Certificates',
        artikelgruppe: 'Product Group | Product Groups',
        lagerplatz: 'Warehouse Yard | Warehouse Yards',
        lager: 'Warehouse | Warehouses',
        belegarchiv: 'Receipt Archive'
    },
    partner: {
        lieferant: 'Supplier | Suppliers',
        kunde: 'Customer | Customers'
    },
    listen: {
        nichtGefunden: 'no {name} found',
        aufklappen: 'unfold',
        zuklappen: 'fold'
    },
    hinzufügen: 'add {name}',
    aktionen: {
        speichern: 'Save',
        abbrechen: 'Cancel',
        löschen: 'Delete'
    },
    operations: {
        created: 'created',
        changed: 'changed',
        deleted: 'deleted',
    }

}