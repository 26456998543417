export default {
  install: function(Vue, options) {
    Vue.mixin({
      computed: {
        isDevelopment() {
          return process.env.NODE_ENV === 'development'
        },
        isMobile() {
          return options ? options.isMobile : undefined;
        }
      }
    })
  }
}
