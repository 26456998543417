import Router from "vue-router";

/*gelöscht, imports

var einkauf_preisanfrage = "16DD32702A5"; // Preisanfrage
var einkauf_bestellung = "16DD3277926"; // Bestellung
var einkauf_auftragsbestätigung = "16DD327A8FB"; // Auftragsbestätigung
var einkauf_lieferschein = "16E269D4639"; // Lieferschein
var einkauf_rechnung = "16E276B19C5"; // Rechnung

var verkauf_angebot = "16FEC7247BE";
var verkauf_auftragsbestätigung = "16FEC76B84F";
var verkauf_lieferschein = "16FEC7F2ADA";
var verkauf_rechnung = "16FEC81BF62";
var verkauf_kontrakt = "16FEC8FD3D4";
var verkauf_gutschrift = "16FEC958DA2";
*/
const baseRoutes = [
  {
    path: "/",
    name: "StartseiteArvea",
    component: () => import("./pages/StartseiteArvea")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("./pages/Login")
  },
  /*{
    path: "/logout/#",
    name: "Logout",
    component: () => import("./pages/Logout")
  },*/
  {
    path: "/calculator",
    name: "Calculator",
    component: () => import("./pages/Calculator")
  },
  {
    path: "/options",
    name: "Options",
    component: () => import("./pages/Options")
  },
  {
    path: "/etf",
    name: "ETF",
    component: () => import("./pages/ETF")
  },
  {
    path: "/etf-single",
    name: "ETF Single",
    component: () => import("./pages/ETFSingle.vue")
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: () => import("./pages/Portfolio")
  },
];

const routes = baseRoutes.concat(
);

export default new Router({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes: routes
});

